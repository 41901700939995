import {
  type HeadersFunction,
  type LoaderFunctionArgs,
  type MetaFunction,
  data,
} from "react-router";
import Carousel from "react-simply-carousel";
import vector from "/images/vector.png";

import greenVector from "/images/green-slim-vector.png";

import {
  getGoogleReviews,
  getLandingPageData,
  getLatestArticles,
} from "app/server/strapi.server";
import { combineServerTimings } from "app/server/timing.server";
import { cacheHeaders } from "app/server/utils.server";
import { ErrorBoundary } from "components/ErrorBoundary.tsx";
import { HomeArticle } from "components/Homepage/HomeArticle.tsx";
import { HomeSection } from "components/Homepage/HomeSection.tsx";
import { MainPageWrapper } from "components/Layout/MainPageWrapper.tsx";
import {
  Accordion,
  Accordion as FAQAccordion,
} from "components/UI/Accordion.tsx";
import { Await, Outlet, useLoaderData } from "react-router";
import DIB from "./_assets+/DIB.webp";
import FAQTwo from "./_assets+/FAQ2.jpg";
import FAQThee from "./_assets+/FAQ3.jpg";
import FAQFour from "./_assets+/FAQ4.jpg";
import FAQFive from "./_assets+/FAQ5.jpg";
import FAQSix from "./_assets+/FAQ6.webp";
import FAQOne from "./_assets+/Faq1.jpg";
import Feraile from "./_assets+/Feraile.webp";
import Biodechet from "./_assets+/biodechet.webp";
import Bois from "./_assets+/bois.webp";
import Carton from "./_assets+/carton.webp";
import DechetsVert from "./_assets+/dechets vert.webp";
import Gravats from "./_assets+/gravats.webp";
import imgHeroSection from "./_assets+/heroSection.jpeg";
import Plastique from "./_assets+/plastique.webp";
import Textile from "./_assets+/textile.webp";

import { formatDateFromNow } from "app/shared/dates.ts";
import { BankNote } from "components/Icons/BankNote";
import { FileSearch } from "components/Icons/FileSearch";
import { PriceNegociation } from "components/Icons/PriceNegociation";
import { User } from "components/Icons/User";
import { Weight } from "components/Icons/Weight";
import { HeroSection, LogoGoogle } from "components/Layout/Global/HeroSection";
import { OptimizedImage } from "components/OptimizedImage";
import { CardArticle } from "components/UI/CardArticle";
import { CardContact } from "components/UI/CardContact";
import { CardSolutions, CardSolutionsWide } from "components/UI/CardSolutions";
import { CardWaste } from "components/UI/CardWaste";
import { Headline } from "components/UI/Headline";
import { Label } from "components/UI/Label";
import { Paragraph } from "components/UI/Paragraph";
import { Rating } from "components/UI/Rating";
import { ClintButton } from "components/shadcn-ui/button";
import { XmarkCircle } from "iconoir-react";
import { Suspense, lazy, useEffect, useMemo, useRef, useState } from "react";
import { generateCanonicalUrl } from "routes/redirects.server";
import { generateMetatags } from "utils/generateMetatags.ts";
import benneAChaine from "./_assets+/benne-a-chaine.png";
import benneAmpliroll2 from "./_assets+/benne-ampliroll-2.png";
import benneAmpliroll from "./_assets+/benne-ampliroll.png";
import benneFermee from "./_assets+/benne-fermee.png";
import franceAnim from "./_assets+/france_anim-3wdSc.json";
import individualImage from "./_assets+/individuals.png";
import professionalImage from "./_assets+/professionnals.jpeg";
import rapidityAnim from "./_assets+/rapidity_anim-BD5VF.json";
import simplicityAnim from "./_assets+/simplicity_anim-5pRyC.json";
import solutionBacsImg from "./_assets+/solution-bacs.png";
import solutionBenneImg from "./_assets+/solution-bennes.jpeg";
import solutionBigBagsImg from "./_assets+/solution-bigbags.jpeg";
import solutionRollsImg from "./_assets+/solution-rolls.jpeg";
import solutionVracImg from "./_assets+/solution-vrac.jpeg";
import traceabilityAnim from "./_assets+/traceability_anim-BJxim.json";
import { FreeAuditSection } from "./conseil";
const LottieComponent = lazy(() => import("lottie-react"));
export { ErrorBoundary };

const dynamicLinks = ({
  data,
}: {
  data: { href?: string };
}): ReturnType<MetaFunction> => [
  {
    rel: "canonical",
    href: data?.href,
  },
];

export const handle = {
  // 👇This is a function that will be called client side with a 'href' argument.
  dynamicLinks,
};

export const loader = async ({ request }: LoaderFunctionArgs) => {
  try {
    const [landingPage, googleReviews, articles] = await Promise.all([
      getLandingPageData(),
      getGoogleReviews(),
      getLatestArticles(),
    ]);

    return data(
      {
        landingPage,
        googleReviews,
        articles,
        href: generateCanonicalUrl({
          request,
        }),
      },
      {
        headers: { ...cacheHeaders },
      },
    );
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const meta: MetaFunction<typeof loader> = ({ data, location }) => {
  if (data?.landingPage.seo) {
    return generateMetatags({
      title: data.landingPage.seo.title,
      description: data.landingPage.seo.description,
      image: data.landingPage.seo.image.url,
      displayWebsite: true,
      displayFaq: true,
      faqItems: data.landingPage.faqItems,
      pageUrl: location.pathname,
    });
  }

  return generateMetatags({
    pageUrl: location.pathname,
  });
};

export const headers: HeadersFunction = ({ loaderHeaders, parentHeaders }) => {
  return {
    ...cacheHeaders,
    "Server-Timing": combineServerTimings(parentHeaders, loaderHeaders),
  };
};

export default function Index() {
  return (
    <MainPageWrapper color="gray" marginTop="none">
      <Outlet />

      <HomeHeroSection />
      <WhyGoodCollectSection />
      <CollectSolutions />

      <ProcessSection />
      <FreeAuditSection
        title="Une question ou un doute ? On vous accompagne."
        description="Vous avez une question ? Contactez-nous!
GoodCollect vous accompagne dans votre démarche."
      />

      <ClientSolutionSection />
      <AdvantagesSection />
      <PickYourEquipmentSection />
      <GoogleReviewsSection />
      <YouCanLearnSection />
      <FaqSection />

      {/* <TryDechetterie /> */}
    </MainPageWrapper>
  );
}

export const HomeHeroSection = () => {
  const {
    landingPage: { brands },
  } = useLoaderData<typeof loader>();

  return (
    <HomeSection
      horizontalPadding={"clint"}
      color={"white"}
      className="py-2 lg:bg-gray-100"
    >
      <HomeArticle
        maxWidth={"1440"}
        horizontalPadding={"none"}
        verticalPadding={"none"}
        color="transparent"
        className="flex-col flex-wrap gap-6 lg:flex-row"
      >
        <HeroSection
          brands={brands}
          className={"h-full  justify-between lg:min-h-[750px]"}
          title="Trouvez la meilleure solution pour vos déchets"
          description="GoodCollect propose des solutions de gestion, collecte et
              traitement de déchets, pour les professionnels, et les
              particuliers ! Trier et valoriser vos déchets partout en France,
              grâce à nos locations de bennes et bigbag."
        />

        <div className="flex w-full flex-1  flex-col items-center gap-6">
          <OptimizedImage
            className="rounded-clint h-[310px] lg:h-full"
            containerClassName="mx-0 relative h-full flex w-full items-center justify-center"
            source={imgHeroSection}
            alt="Tri des déchets et des bennes"
            imageProps={{ objectFit: "cover", minHeight: "auto" }}
            maxWidth={1000}
            width={1710}
            height={714}
          >
            <div className="absolute inset-0 z-10 flex h-full w-full items-center justify-center overflow-hidden">
              <img src={vector} className="" width={530} height={530}  alt="cercle GoodCollect" />
            </div>
          </OptimizedImage>
          <div className="flex w-full flex-wrap gap-4">
            <CardWaste
              title="Les types de déchets pris en charge"
              className="min-h-[310px] shrink-0 grow basis-[264px]"
              elements={[
                {
                  tag: "DIB",
                  image: {
                    url: DIB,
                    placement: "object-right",
                  },
                },
                { tag: "Gravats", image: { url: Gravats } },
                { tag: "Carton", image: { url: Carton } },
                { tag: "Biodéchets", image: { url: Biodechet } },

                { tag: "Féraille", image: { url: Feraile } },
                { tag: "Plastiques", image: { url: Plastique } },
                {
                  tag: "Dechets verts",
                  image: { url: DechetsVert, placement: "object-center" },
                },
                { tag: "Bois", image: { url: Bois } },

                { tag: "Textile", image: { url: Textile } },
              ]}
            />
            <CardContact
              title="Contactez-nous pour un devis"
              className={"min-h-[310px] shrink-0 grow basis-[264px]"}
            />
          </div>
        </div>
      </HomeArticle>
    </HomeSection>
  );
};

export const CollectSolutions = () => {
  const Tags = [
    "Commerçants",
    "Artisans",
    "Constructeurs",
    "Industriels",
    "Etc...",
  ];

  return (
    <HomeSection
      horizontalPadding={"clint"}
      verticalPadding={"none"}
      color="transparent"
    >
      <HomeArticle
        verticalPadding={"clint"}
        className="flex flex-col gap-10 border-t border-t-gray-300"
      >
        <div className="flex flex-row flex-wrap gap-4 lg:justify-between">
          <Headline as="h2" size={"h2"}>
            Des solutions de recyclage pour vos déchets
          </Headline>
          <Label as="p" size={"M"} className="max-w-[60ch] text-gray-600">
            Entreprise spécialisée dans la location de bennes, collecte et
            traitement des déchets, nous vous aidons à garder un environnement
            propre et à participer au développement d'une société durable. Nos
            solutions de valorisation des déchets s'adressent aux entreprises et
            particuliers.
          </Label>
        </div>
        <div className="flex flex-row flex-wrap justify-between gap-4">
          <CardSolutions
            alt="Location de bennes pour les professionnels"
            bgImg={professionalImage}
            preTitle="Pour les"
            title={"Professionnels"}
            button={{
              as: "Link",
              linkProps: {
                to: "/solutions/location-de-bennes",
                obfuscate: true,
              },
              variant: "primary",
              children: "Solutions pour les pros",
            }}
            tags={Tags}
            className="grow"
          >
            Vous êtes commerçant, artisan, constructeur, industriel, agriculteur
            ou restaurateur et cherchez une solution pour vos déchets ? Utilisez
            la solution GoodCollect pour faire évacuer rapidement vos déchets à
            moindre coût.
          </CardSolutions>
          <CardSolutions
            alt="Location de bennes pour les particuliers"
            bgImg={individualImage}
            preTitle="Pour les"
            title="Particuliers"
            button={{
              as: "Link",
              linkProps: {
                to: "/solutions/location-de-bennes",
                obfuscate: true,
              },
              variant: "primary",
              children: "Solutions pour les particuliers",
            }}
            tags={[]}
            className="grow"
          >
            Que vous ayez besoin de vider une maison, d’évacuer des déchets
            verts, des gravats issus de travaux, de la terre de remblais.
            GoodCollect vous propose une solution simple et économique. Faites
            enlever vos déchets du quotidien rapidement et à moindre coût grâce
            à notre service adapté à vos besoins.
          </CardSolutions>
        </div>
      </HomeArticle>
    </HomeSection>
  );
};

const ProcessSection = () => {
  const {
    landingPage: { processSection },
  } = useLoaderData<typeof loader>();
  const [DrawerOpen, setDrawerOpen] = useState(1);
  function onValueChange(value: number) {
    if (value < 1) {
      value = 1;
    }
    setDrawerOpen(value);
  }
  const FAQSections: {
    description: string;
    id: number;
    title: string;
    numbered: boolean;
    number: number;
    img: string;
    overlay: React.ReactNode;
  }[] = [
    {
      description:
        "Vous avez un déchet ? Gratuitement en quelques clics, précisez votre besoin : code postal, volume et type de déchet.",
      id: 1,
      title: "Identifier votre besoin",
      numbered: true,
      number: 1,
      img: FAQOne,
      overlay: null,
    },
    {
      description:
        "Les offres de nombreux partenaires certifiés et locaux. (Prix / émissions de Co2 / type de traitement / type de transport).",
      id: 2,
      title: "Comparez",
      numbered: true,
      number: 2,
      img: FAQTwo,
      overlay: <div className="absolute z-10 h-full w-full"></div>,
    },
    {
      description:
        "Réservez quand vous le souhaitez en quelques clics, la solution de votre choix adaptée à votre besoin.",
      id: 3,
      title: "Réservez",
      numbered: true,
      number: 3,
      img: FAQThee,
      overlay: <div className="absolute z-10 h-full w-full"></div>,
    },
    {
      description:
        "Au moment de la réservation si vous êtes un particulier ou par prélèvement à la fin du mois si vous êtes une entreprise certifiée.",
      id: 4,
      title: "Payer",
      numbered: true,
      number: 4,
      img: FAQFour,
      overlay: <div className="absolute z-10 h-full w-full"></div>,
    },
    {
      description:
        "Une fois la prestation terminée, si vous le souhaitez, vous pouvez laisser un commentaire.",
      id: 5,
      title: "Notez",
      numbered: true,
      number: 5,
      img: FAQFive,
      overlay: <div className="absolute z-10 h-full w-full"></div>,
    },
    {
      description:
        "Vous bénéficiez d'une assistance 24 heures sur 24, 7 jours sur 7.",
      id: 6,
      title: "Accompagnement client",
      numbered: true,
      number: 6,
      img: FAQSix,
      overlay: <div className="absolute z-10 h-full w-full"></div>,
    },
  ];

  const currentImage = FAQSections[DrawerOpen - 1];

  return (
    <HomeSection
      color="white"
      horizontalPadding={"clint"}
      verticalPadding={"clint"}
    >
      <HomeArticle
        color="transparent"
        horizontalPadding="none"
        verticalPadding={"none"}
        className="flex flex-col gap-10"
      >
        <div className="flex flex-row flex-wrap justify-between gap-4">
          <Headline size={"h2"} className="flex-1">
            {processSection.title} ?
          </Headline>
          <Label as="p" size={"M"} className="lg:basis-[586px]">
            {processSection.description}
          </Label>
        </div>
        <div className="relative flex justify-between gap-5 lg:gap-10">
          <div className="w-full lg:basis-[672px]">
            <FAQAccordion
              items={FAQSections}
              theme="drawer"
              onValueChange={(v) => onValueChange(Number(v))}
            />
          </div>
          <OptimizedImage
            containerClassName="relative mx-0 hidden lg:block mt-auto"
            source={currentImage.img}
            alt={currentImage.title}
            imageProps={{ minHeight: "100%", objectFit: "contain" }}
            width={642}
            height={546}
            maxWidth={642}
          >
            {currentImage.overlay}
          </OptimizedImage>
        </div>
      </HomeArticle>
    </HomeSection>
  );
};

const WhyGoodCollectSection = () => {
  const {
    landingPage: { whySection },
  } = useLoaderData<typeof loader>();

  const Chooses = useMemo(
    () => [
      {
        animPath: simplicityAnim,
        img: (
          <svg
            width="80"
            height="80"
            viewBox="0 0 72 72"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="36"
              cy="36"
              r="35.5"
              transform="rotate(-180 36 36)"
              stroke="#14B480"
            />
          </svg>
        ),

        choose: "Simplicité",
        argument:
          "Choisissez la solution de votre choix en accord avec votre budget et vos convictions",
      },
      {
        animPath: traceabilityAnim,
        choose: "Traçabilité",
        img: (
          <svg
            width="80"
            height="80"
            viewBox="0 0 72 72"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_1_2)">
              <path
                d="M36.0012 71.5C44.133 71.5 50.7251 64.9079 50.7251 56.7761C50.7251 48.6443 44.133 42.0522 36.0012 42.0522C27.8694 42.0522 21.2773 48.6443 21.2773 56.7761C21.2773 64.9079 27.8694 71.5 36.0012 71.5Z"
                stroke="#14B480"
              />
              <path
                d="M36 20.918C22.0322 20.918 10.709 32.2412 10.709 46.209C10.709 60.1768 22.0322 71.5 36 71.5C49.9678 71.5 61.291 60.1768 61.291 46.209C61.291 32.2412 49.9678 20.918 36 20.918Z"
                stroke="#14B480"
              />
              <path
                d="M36 71.5C55.6061 71.5 71.5 55.6061 71.5 36C71.5 16.3939 55.6061 0.5 36 0.5C16.3939 0.5 0.5 16.3939 0.5 36C0.5 55.6061 16.3939 71.5 36 71.5Z"
                stroke="#14B480"
              />
            </g>
            <defs>
              <clipPath id="clip0_1_2">
                <rect
                  width="72"
                  height="72"
                  fill="white"
                  transform="matrix(-1 0 0 -1 72 72)"
                />
              </clipPath>
            </defs>
          </svg>
        ),
        argument: "Offrez vous l'assurance de de la traçabilité de vos déchets",
      },
      {
        animPath: rapidityAnim,
        choose: "Rapidité",
        img: (
          <svg
            width="80"
            height="80"
            viewBox="0 0 73 73"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.5 36C10.5 21.9167 21.9167 10.5 36 10.5C50.0833 10.5 61.5 21.9167 61.5 36C61.5 50.0833 50.0833 61.5 36 61.5C21.9167 61.5 10.5 50.0833 10.5 36Z"
              stroke="#14B480"
            />
            <circle
              cx="15.2234"
              cy="57.2239"
              r="14.7239"
              transform="rotate(-180 15.2234 57.2239)"
              stroke="#14B480"
            />
            <circle
              cx="57.2234"
              cy="15.2239"
              r="14.7239"
              transform="rotate(-180 57.2234 15.2239)"
              stroke="#14B480"
            />
            <circle
              cx="15.2239"
              cy="15.2239"
              r="14.7239"
              transform="rotate(-180 15.2239 15.2239)"
              stroke="#14B480"
            />
            <circle
              cx="57.2234"
              cy="57.2239"
              r="14.7239"
              transform="rotate(-180 57.2234 57.2239)"
              stroke="#14B480"
            />
          </svg>
        ),
        argument:
          "En quelques clics, accédez à un service de recyclage pour vos déchets.",
      },
      {
        animPath: franceAnim,
        choose: "National",
        img: (
          <svg
            width="80"
            height="80"
            viewBox="0 0 74 72"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M35.3896 2.95588L41.4156 1L42.974 5.11765L49.2078 9.02941V11.3971L54.4026 10.4706L55.8571 13.25L73 20.0441L68.1169 32.0882H65.9351L60.1169 39.3971L60.7403 42.0735L64.0649 40.5294L65.9351 48.9706L64.5844 52.2647L65.9351 54.3235L65.1039 56.1765L70.2987 58.75L61.4675 66.6765L49.2078 63.1765L44.2208 67.2941L43.5974 71H36.4286L31.6494 68.9412L28.6364 69.7647L17.4156 64L19.9091 61.1176L20.6364 47.4265L18.7662 41.4559L12.9481 32.9118L2.66234 29.4118L1.51948 27.0441L2.66234 26.1176L1 23.6471L9.51948 20.8676L11.5974 23.0294L18.7662 21.7941L16.2727 13.9706L19.9091 13.25L20.6364 16.0294H28.013V13.25L34.8701 10.4706L35.3896 2.95588Z"
              stroke="#14B480"
            />
          </svg>
        ),
        argument:
          "GoodCollect vous propose un service national, disponible partout en France",
      },
    ],
    [],
  );
  const [isAnimComplete, setIsAnimComplete] = useState(false);
  const [startAnimation, setStartAnimation] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    const handleIntersection = (entries) => {
      const [entry] = entries;
      if (entry.isIntersecting) {
        setStartAnimation(true);
      }
    };

    const observer = new IntersectionObserver(handleIntersection, {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    });

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  // Suppression de l'effet qui définissait startAnimation à true immédiatement
  // car cela contredisait la logique d'intersection

  return (
    <>
      <HomeSection
        horizontalPadding="clint"
        color="gray100"
        verticalPadding={"clint"}
      >
        <HomeArticle
          horizontalPadding={"none"}
          color="transparent"
          className="flex flex-col gap-10 lg:px-[86px]"
          verticalPadding={"none"}
        >
          <Headline size={"h4"} as="h2" className="lg:mb-[42px]">
            {whySection.title}
          </Headline>

          <div ref={sectionRef} className="flex w-full flex-wrap gap-10">
            {Chooses.map((choose, index) => (
              <div
                key={`${index}-${choose.choose}`}
                className="basis-[140px] sm:basis-[22%]"
              >
                {startAnimation && !isAnimComplete ? (
                  <Suspense fallback={<div>Loading...</div>}>
                    <LottieComponent
                      className="mb-[1.125rem] size-20"
                      animationData={choose.animPath}
                      loop={true}
                      autoPlay={false}
                      onLoopComplete={() => {
                        setIsAnimComplete(true);
                      }}
                    />
                  </Suspense>
                ) : (
                  <div className="size-20 mb-[1.125rem]">{choose.img}</div>
                )}

                <Headline size={"h6"} as="h3" className="mb-3">
                  {choose.choose}
                </Headline>
                <Paragraph
                  size={"sm"}
                  className="max-w-[20ch] text-gray-700 lg:max-w-[40ch]"
                >
                  {choose.argument}
                </Paragraph>
              </div>
            ))}
          </div>
        </HomeArticle>
      </HomeSection>
    </>
  );
};

const ClientSolutionSection = () => {
  return (
    <HomeSection
      color="gray100"
      horizontalPadding={"clint"}
      verticalPadding={"clint"}
    >
      <HomeArticle
        horizontalPadding="none"
        verticalPadding={"none"}
        className="flex flex-col gap-10"
      >
        <div className="flex flex-col gap-10">
          <div className="flex flex-col gap-4 lg:gap-10">
            <div className="flex flex-wrap items-start gap-5 lg:gap-10">
              <Headline as="h2">Toutes nos solutions pour nos clients</Headline>
              <Label size="M" className="max-w-[65ch] text-gray-600">
                Nous vous aidons à optimiser la chaîne de valeur de la gestion
                de vos déchets avec des solutions de collecte pour les
                professionnels, les collectivités et les particuliers dans toute
                la France. En combinant des prestataires certifiés avec une
                technologie logistique et des services de pointe, notre
                plateforme débloque les meilleures opportunités et options pour
                les établissements professionnels de tout secteur et
                particuliers à la recherche de solutions pour leurs déchets en
                France.
              </Label>
            </div>
            <ClintButton
              as="Link"
              linkProps={{
                to: "/solutions",
              }}
              variant={"primary"}
              className="w-fit"
            >
              Voir toutes nos solutions
            </ClintButton>
          </div>
        </div>
        <div className="grid-cols-largeSolution grid w-full gap-[22px]">
          <CardSolutionsWide
            alt="Solutions GoodCollect pour location de bennes"
            bgImg={solutionBenneImg}
            button={{
              as: "Link",
              linkProps: {
                to: "/solutions/location-de-bennes",
              },
              className: "w-fit",
              children: "Rechercher une benne",
              variant: "secondary",
            }}
            title="Location benne"
            preTitle="Nos Solutions"
            className="max-lg:h-[460px]"
          >
            Louer une benne pour vos déchets ne doit pas être pris à la légère :
            découvrez les tarifs et délais de réservation pour des locations et
            retraits des bennes GoodCollect
          </CardSolutionsWide>
          <CardSolutionsWide
            alt="Solutions GoodCollect pour enlevement Big Bag"
            className="max-lg:h-[460px]"
            bgImg={solutionBigBagsImg}
            button={{
              as: "Link",
              linkProps: {
                to: "/solutions/enlevement-big-bag",
              },
              className: "w-fit",
              children: "Rechercher un Big Bag",
              variant: "secondary",
            }}
            title="Enlevement Big Bag"
            preTitle="Nos Solutions"
          >
            L'enlèvement de ses big bags de chantier à un bon prix pour vos
            déchets : découvrez nos prix, zones et délais d'intervention
          </CardSolutionsWide>
        </div>
        <div className="grid-cols-smallSolution grid w-full gap-[22px]">
          <CardSolutionsWide
            alt="Solutions GoodCollect pour collecte en vrac"
            bgImg={solutionVracImg}
            button={{
              as: "Link",
              linkProps: {
                // to: "/solutions/location-de-bennes",
                to: `/contact?description=Bonjour, je souhaiterais être notifié quand le service "Collecte en vracs" sera disponible`,
              },
              className: "w-fit",
              children: "Demander un devis",
              variant: "secondary",
            }}
            title="Collecte en vrac"
            preTitle="Nos Solutions"
          ></CardSolutionsWide>
          <CardSolutionsWide
            alt="Solutions GoodCollect pour location de rolls"
            bgImg={solutionRollsImg}
            button={{
              as: "Link",
              linkProps: {
                // to: "/solutions/location-de-bennes",
                to: `/contact?description=Bonjour, je souhaiterais être notifié quand le service "Collecte en rolls" sera disponible`,
              },
              children: "Demander un devis",
              variant: "secondary",
              className: "w-fit",
            }}
            title="Rolls"
            preTitle="Nos Solutions"
          ></CardSolutionsWide>
          <CardSolutionsWide
            alt="Solutions GoodCollect pour collecte en bacs"
            bgImg={solutionBacsImg}
            button={{
              as: "Link",
              linkProps: {
                to: `/contact?description=Bonjour, je souhaiterais être notifié quand le service "Collecte en bacs" sera disponible`,
              },
              children: "Demander un devis",
              variant: "secondary",
              className: "w-fit",
            }}
            title="Bacs"
            preTitle="Nos Solutions"
          ></CardSolutionsWide>
        </div>
      </HomeArticle>
    </HomeSection>
  );
};

export const advantages = [
  {
    id: 1,
    name: "Traçabilité des déchets",
    icon: (
      <FileSearch className="text-primary size-8 shrink-0" aria-hidden="true" />
    ),
  },
  {
    id: 4,
    name: "Prix négociés",
    icon: (
      <PriceNegociation
        className="text-primary size-8 shrink-0"
        aria-hidden="true"
      />
    ),
  },
  {
    id: 2,
    name: "Annulation possible",
    icon: (
      <XmarkCircle
        className="text-primary size-8 shrink-0"
        strokeWidth={1.5}
        aria-hidden="true"
      />
    ),
  },
  {
    id: 5,
    name: "Gestion de paiement",
    icon: (
      <BankNote className="text-primary size-8 shrink-0" aria-hidden="true" />
    ),
  },
  {
    id: 3,
    name: "Facturation à la tonne",
    icon: (
      <Weight className="text-primary size-8 shrink-0" aria-hidden="true" />
    ),
  },

  {
    id: 6,
    name: "Espace personnel",
    icon: <User className="text-primary size-8 shrink-0" aria-hidden="true" />,
  },
];
const AdvantagesSection = () => {
  const Section = ({ advantage }: { advantage: (typeof advantages)[0] }) => {
    return (
      <div className="flex w-full max-w-[242px] flex-col gap-2 border-l border-r-gray-500 pl-3 h-full">
        {advantage.icon}
        <Label size="M" as="p" variant={"white"} className="w-full grow">
          {advantage.name}
        </Label>
      </div>
    );
  };
  return (
    <HomeSection
      color="midnightblue"
      verticalPadding={"clintLarge"}
      horizontalPadding={"clintLarge"}
    >
      <HomeArticle
        color={"transparent"}
        // verticalPadding={"clint"}
      >
        <div className="flex w-full flex-wrap lg:flex-nowrap justify-between gap-10 lg:gap-2">
          <div className="flex max-w-[65ch] flex-col gap-3.5">
            <Headline size={"h2"} variant="white" className="max-w-[18ch]">
              Les avantages à utiliser les solutions GoodCollect
            </Headline>
            <Label size="M" className="text-gray-200">
              Nous récupérons les déchets pour leur offrir le traitement adapté
              et assurer la propreté des lieux qu'ils encombrent. Cette première
              action de récupération pour le bon traitement des déchets
              (collecte séparée, tri, revalorisation) est soutenue par une
              technologie innovante et une équipe d'experts du domaine.
            </Label>
          </div>

          <div className="text-primary grid w-fit basis-[484px] grid-cols-2 items-end justify-end gap-x-4 gap-y-8 ">
            {advantages.map((adv) => (
              <Section advantage={adv} key={adv.id} />
            ))}
          </div>
        </div>
      </HomeArticle>
    </HomeSection>
  );
};

const PickYourEquipmentSection = () => {
  const [activeSlide, setActiveSlide] = useState(0);
  const Section = ({
    image,
    title,
    volume,
  }: { title: string; image: string; volume: string }) => {
    const MobileWrapper = ({ children }: { children: React.ReactNode }) => {
      return (
        <>
          <div className="w-full min-w-[80vw] p-2 lg:min-w-fit  lg:hidden overflow-hidden">
            {children}
          </div>
          <div className="hidden lg:flex lg:max-w-full  lg:basis-[260px] m-auto xl:basis-[280px]">
            {children}
          </div>
        </>
      );
    };
    return (
      <MobileWrapper>
        <div className="rounded-clint flex w-full grow flex-col gap-8 bg-gray-100 p-6 overflow-hidden">
          <Headline size="h5" as="div">
            {title} <br /> {volume}
          </Headline>

          <OptimizedImage
            width={952}
            height={383}
            maxWidth={300}
            source={image}
            containerClassName="mx-0"
            imageProps={{
              minHeight: "100%",
              objectFit: "contain",
            }}
            alt={title}
          >
            <div className="absolute inset-0 z-0 flex h-full w-full items-center justify-center">
              <OptimizedImage
                source={greenVector}
                width={328}
                height={384}
                maxWidth={260}
                className=""
                containerClassName="mx-0"
                alt="cercle vert GoodCollect"
              />
            </div>
          </OptimizedImage>
        </div>
      </MobileWrapper>
    );
  };
  return (
    <HomeSection
      color="white"
      verticalPadding={"clintLarge"}
      horizontalPadding={"clint"}
    >
      <HomeArticle color={"transparent"}>
        <div className="flex w-full flex-col gap-6 lg:gap-[3.75rem]">
          <div className="flex flex-row flex-wrap items-end justify-between gap-4 lg:gap-8">
            <Headline
              size={"h2"}
              variant="secondaryDarker"
              className="max-w-[18ch]"
            >
              Vous choisissez votre benne, on s'occupe du reste !
            </Headline>

            <Label size="M" as="p" className="block max-w-[65ch] text-gray-600">
              Avec Goodcollect, vous consultez, réservez et payez en ligne les
              meilleurs services de location de bennes, collecte et traitement
              de vos déchets au meilleur prix !
            </Label>
          </div>
          <ClintButton
            as="Link"
            linkProps={{
              to: "/solutions/location-de-bennes",
            }}
            variant={"primary"}
            className="lg:w-fit"
          >
            Voir toutes nos bennes
          </ClintButton>
        </div>
        <div className="mt-10 mx-auto hidden flex-wrap items-center gap-x-4 gap-y-8 lg:mt-12 lg:flex ">
          <Section
            image={benneAmpliroll}
            title="Benne Ampliroll"
            volume="15m3"
          />
          <Section
            image={benneAmpliroll2}
            title="Benne Ampliroll"
            volume="30m3"
          />
          <Section image={benneFermee} title="Benne fermée" volume="15m3" />
          <Section image={benneAChaine} title="Benne à chaine" volume="10m3" />
        </div>
        <div className="w-full lg:hidden">
          <Carousel
            containerProps={{
              style: {
                width: "100%",
                justifyContent: "space-between",

                // userSelect: "none"
              },
            }}
            dotsNav={{
              show: true,
              containerProps: {
                style: {
                  display: "flex",
                  gap: 8,
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                },
              },
              itemBtnProps: {
                style: {
                  height: 16,
                  width: 16,
                  borderRadius: "50%",
                  border: 0,
                  background: "#d9f0e5",
                },
              },
              activeItemBtnProps: {
                style: {
                  height: 16,
                  width: 16,
                  borderRadius: "50%",
                  border: 0,
                  background: "hsl(161, 80%, 39%)",
                },
              },
            }}
            preventScrollOnSwipe
            // autoplay
            autoplayDelay={10000}
            swipeTreshold={60}
            activeSlideIndex={activeSlide}
            infinite
            onRequestChange={setActiveSlide}
            activeSlideProps={{
              style: {
                transition: "all 0.5s",
                width: 8,
                height: 8,
                backgroundColor: "#000",
              },
            }}
            itemsToShow={2}
            speed={750}
            // centerMode={true}
          >
            <Section
              image={benneAmpliroll}
              title="Benne Ampliroll"
              volume="15m3"
            />
            <Section
              image={benneAmpliroll2}
              title="Benne Ampliroll"
              volume="30m3"
            />
            <Section image={benneFermee} title="Benne à chaine" volume="10m3" />
            <Section image={benneAChaine} title="Benne fermée" volume="20m3" />
          </Carousel>
        </div>
      </HomeArticle>
    </HomeSection>
  );
};

const GoogleReview = ({
  review,
}: {
  review: Awaited<ReturnType<typeof getGoogleReviews>>[0];
}) => {
  return (
    <div className="rounded-clint flex h-[232px] w-[328px] grow basis-[308px] flex-col gap-2 overflow-hidden overflow-y-auto border border-green-200  p-6">
      <div className="flex flex-wrap items-center justify-between gap-2">
        <Rating percent={(review.rating / 5) * 100} />
        <Label size="S" className="text-gray-600">
          {formatDateFromNow({
            date: review.date,
            dateFmt: "dd/MM/yyyy",
            maxDays: 30000000,
          })}
        </Label>
      </div>
      {review.title ? (
        <Label size="M" as="p">
          {review.title}
        </Label>
      ) : null}
      {review.testimonial ? (
        <Paragraph size="sm" className="text-gray-600">
          {review.testimonial}
        </Paragraph>
      ) : null}
      <div className="mt-auto flex items-center gap-2">
        <div className="flex size-11 items-center justify-center rounded-full bg-green-100">
          <Label size="XS">
            {review.name
              .split(" ")
              .map((word) => word.charAt(0).toUpperCase())
              .join("")
              .slice(0, 2)}
          </Label>
        </div>
        <Label size="S" as="p">
          {review.name}
        </Label>
      </div>
    </div>
  );
};
const GoogleReviewsSection = () => {
  const { googleReviews: googleReviewsPromise } =
    useLoaderData<typeof loader>();

  return (
    <HomeSection
      color="gray100"
      verticalPadding={"clintLarge"}
      horizontalPadding={"none"}
    >
      <HomeArticle color={"transparent"}>
        <div className="flex w-full flex-col items-center gap-8 lg:gap-16">
          <div className="flex w-full flex-col items-center gap-4 px-5 lg:px-10">
            <div className="flex items-center gap-2">
              <Rating percent={(4.9 / 5) * 100} />
              <Label size={"S"} className="text-gray-600">
                4.95/5
              </Label>
              {LogoGoogle}
            </div>

            <Headline
              size={"h2"}
              variant="secondaryDarker"
              className="text-center"
            >
              Ils en parlent mieux que nous
            </Headline>
          </div>
          <div className="relative w-[100vw] overflow-hidden">
            <div className="gradientLogos pointer-events-none absolute inset-0 z-20 h-full w-full"></div>
            <div className="flex flex-col gap-6">
              <Suspense fallback={null}>
                <Await resolve={googleReviewsPromise}>
                  {(googleReviews) => {
                    const googleReviewsArrayOne = googleReviews.slice(
                      0,
                      googleReviews.length / 2,
                    );
                    const googleReviewsArrayTwo = googleReviews.slice(
                      googleReviews.length / 2,
                      googleReviews.length,
                    );

                    return (
                      <>
                        <div
                          className="animate-slide-left flex h-full w-max items-center gap-4"
                          style={{
                            animationDuration: `${googleReviewsArrayOne.length * 7.5}s`,
                          }}
                        >
                          {googleReviewsArrayOne.map((gr) => (
                            <GoogleReview key={gr.id} review={gr} />
                          ))}
                          {googleReviewsArrayOne.map((gr) => (
                            <GoogleReview key={`alias-${gr.id}`} review={gr} />
                          ))}
                        </div>
                        <div
                          className="animate-slide-right flex h-full w-max items-start gap-4"
                          style={{
                            animationDuration: `${googleReviewsArrayTwo.length * 7.5}s`,
                          }}
                        >
                          {googleReviewsArrayTwo.map((gr) => (
                            <GoogleReview key={gr.id} review={gr} />
                          ))}
                          {googleReviewsArrayTwo.map((gr) => (
                            <GoogleReview key={`alias-${gr.id}`} review={gr} />
                          ))}
                        </div>
                      </>
                    );
                  }}
                </Await>
              </Suspense>
            </div>
          </div>
        </div>
      </HomeArticle>
    </HomeSection>
  );
};

const YouCanLearnSection = () => {
  const { articles: articlesPromise } = useLoaderData<typeof loader>();
  return (
    <HomeSection
      color="white"
      verticalPadding={"clintLarge"}
      horizontalPadding={"clint"}
    >
      <HomeArticle color={"transparent"}>
        <div className="flex w-full flex-col gap-6">
          <div className="flex flex-row flex-wrap items-end justify-between gap-4 lg:gap-8">
            <Headline
              size={"h2"}
              variant="secondaryDarker"
              className="max-w-[18ch]"
            >
              Être expert du recyclage, ça s'apprend
            </Headline>

            <Label size="M" as="p" className="block max-w-[65ch] text-gray-600">
              Avec Goodcollect, vous consultez, Retrouvez tous nos articles pour
              répondre à vos questions sur l'équipement, la législation ou les
              dernières innovations sur les méthodes de recyclage.
            </Label>
          </div>

          <ClintButton
            as="Link"
            linkProps={{
              to: "/solutions/location-de-bennes",
            }}
            variant={"primary"}
            className="w-fit"
          >
            Toutes nos ressources
          </ClintButton>
        </div>
        <Suspense>
          <Await resolve={articlesPromise}>
            {(articles) => (
              <div className="grid-cols-smallBlogArticle mt-10 grid gap-4 lg:mt-12">
                {articles.map((article) => (
                  <CardArticle
                    article={article}
                    key={article.slug}
                    darkmode
                    textClassname="h-full"
                  />
                ))}
              </div>
            )}
          </Await>
        </Suspense>
      </HomeArticle>
    </HomeSection>
  );
};

const FaqSection = () => {
  const {
    landingPage: { faqItems },
  } = useLoaderData<typeof loader>();
  return (
    <HomeSection
      className="py-10"
      color="gray100"
      verticalPadding={"clint"}
      horizontalPadding={"clint"}
    >
      <HomeArticle color={"transparent"} verticalPadding={"none"}>
        <div className="flex w-full flex-wrap justify-between gap-10">
          <div className="flex basis-[585px] flex-col gap-2 lg:gap-6">
            <Label size="M" as="p" className="block max-w-[65ch] text-gray-600">
              FAQ
            </Label>
            <Headline
              size={"h2"}
              variant="secondaryDarker"
              className="max-w-[18ch]"
            >
              Tout savoir sur le traitement de vos déchets
            </Headline>
          </div>

          <Accordion
            theme="faq"
            className="grow basis-[652px]"
            items={faqItems.map((item) => ({
              description: item.description,
              id: item.id,
              title: item.title,
            }))}
          />
        </div>
      </HomeArticle>
    </HomeSection>
  );
};
