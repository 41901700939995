import { OptimizedImage } from "components/OptimizedImage";
import { useState } from "react";
import { cn } from "utils/utils";
import cercle from "../../routes/_public+/_assets+/cercle.svg";
import { Headline } from "./Headline";
import { Tag } from "./Tag";
export const CardWaste = ({
  elements,
  title,
  className,
}: {
  title: string;
  className?: string;
  elements: {
    tag: string;
    image: { url: string; placement?: string };
  }[];
}) => {
  const [hoverTag, setHoverTag] = useState<number>(0);

  return (
    <div
      className={cn(
        "bg-green-200 p-5 rounded-clint relative w-fit min-h-[318px] overflow-hidden",
        className,
      )}
    >
      <Headline size="h4" as="div" variant="secondary" className="z-10 mb-3.5">
        {title}
      </Headline>
      <div className="relative z-10 flex flex-wrap gap-1.5">
        {elements.map((element, index) => (
          <Tag
            variant="Light"
            key={element.tag}
            type="Rounded"
            className={`${hoverTag === index ? "bg-green-0 text-green-600" : "bg-green-100 text-green-300 hover:bg-green-0 hover:text-green-600"}`}
            // disabled={hoverTag !== index}
            onMouseEnter={() => setHoverTag(index)}
          >
            {element.tag}
          </Tag>
        ))}
      </div>

      <OptimizedImage
        source={elements[hoverTag].image.url}
        alt={elements[hoverTag].tag}
        containerClassName="relative max-w-[144px] ml-auto absolute bottom-[10px] right-[10px]"
        width={144}
        height={144}
        imageProps={{
          minHeight: "100%",
          objectFit: "cover",
        }}
        className={`${elements[hoverTag].image.placement ?? "object-center"} z-1  max-w-[144px] rounded-full`}
      ></OptimizedImage>
      <img
        className={`absolute  bottom-0 right-0 z-0`}
        src={cercle}
        alt="cercle GoodColelct"
      />
    </div>
  );
};
