import { useEnv } from "app/root";
import {
  getConseilData,
  getTestimonialsSection,
} from "app/server/strapi.server";
import { cacheHeaders } from "app/server/utils.server";
import { ErrorBoundary } from "components/ErrorBoundary.tsx";
import { HomeArticle } from "components/Homepage/HomeArticle.tsx";
import { HomeSection } from "components/Homepage/HomeSection.tsx";
import { LogoBanner } from "components/Layout/Global/HeroSection";
import { MainPageWrapper } from "components/Layout/MainPageWrapper.tsx";
import { OptimizedImage } from "components/OptimizedImage";
import { TestimonialsSection } from "components/Sections/TestimonialsSection.tsx";
import TryDechetterie from "components/Sections/TryDechetterie.tsx";
import { CardContact } from "components/UI/CardContact";
import { Headline } from "components/UI/Headline";
import { Label } from "components/UI/Label";
import { Paragraph } from "components/UI/Paragraph";
import { Tag } from "components/UI/Tag";
import { ClintButton } from "components/shadcn-ui/button";
import { useTailwindScreenSizes } from "hooks/useTailwindScreenSizes";
import { MagicWand, Mail, Phone } from "iconoir-react";
import {
  type LoaderFunctionArgs,
  type MetaFunction,
  data,
  useLoaderData,
} from "react-router";
import { generateCanonicalUrl } from "routes/redirects.server";
import { generateMetatags } from "utils/generateMetatags.ts";
import { advantages } from ".";
import appelDoffres from "./_assets+/_conseil+/appel-doffres.jpeg";
import commerce from "./_assets+/_conseil+/commerce.png";
import conseil from "./_assets+/_conseil+/conseil.jpg";
import digitalisationDechetsCustom from "./_assets+/_conseil+/digitalisation-dechets-custom.png";
import etudesSpecifiques from "./_assets+/_conseil+/etudes-specifiques.png";
import excellenceOperationnelle from "./_assets+/_conseil+/excellence-operationnelle.png";
import gestionDeleguee from "./_assets+/_conseil+/gestion-deleguee.png";
import industriel from "./_assets+/_conseil+/industriel.jpeg";
import integrationCo2Dechet from "./_assets+/_conseil+/integration-co2-dechet.png";
import logistique from "./_assets+/_conseil+/logistique.png";
import nouvellesFilieres from "./_assets+/_conseil+/nouvelles-filieres.png";
import restauration from "./_assets+/_conseil+/restauration.png";
import retail from "./_assets+/_conseil+/retail.png";
import vector from "/images/vector.png";
import whiteVector from "/images/white-vector.png";

export { ErrorBoundary };

const dynamicLinks = ({
  data,
}: {
  data: { href: string };
}): ReturnType<MetaFunction> => [
  {
    rel: "canonical",
    href: data.href,
  },
];

export const handle = {
  // 👇This is a function that will be called client side with a 'href' argument.
  dynamicLinks,
};

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const [conseilPageData, testimonialData] = await Promise.all([
    getConseilData(),
    getTestimonialsSection(),
  ]);

  const href = generateCanonicalUrl({
    request,
  });
  return data(
    {
      testimonialData,
      conseilPageData,
      seo: conseilPageData.seo,
      href,
    },
    {
      headers: cacheHeaders,
    },
  );
};
export const headers = cacheHeaders;

export const meta: MetaFunction<typeof loader> = ({ data, location }) => {
  if (data?.seo) {
    return generateMetatags({
      title: data.seo.title,
      description: data.seo.description,
      image: data.seo.image.url,
      pageUrl: location.pathname,
    });
  }

  return generateMetatags({ pageUrl: location.pathname });
};

const ConseilPage = () => {
  const { testimonialData } = useLoaderData<typeof loader>();

  return (
    <MainPageWrapper color="gray-100">
      <HeroSection />
      <TrustedByBrands />
      <AuditSection />
      <ImprovePerformanceSection />
      <FreeAuditSection
        title="Un premier audit gratuit pour envisager une collaboration"
        description="Vous avez une question ? Contactez-nous! GoodCollect vous accompagne
            dans votre démarche."
      />
      <ActivitySectorsSection />
      <BenefitsSection />
      <ChiffresClesSection />

      <TestimonialsSection
        title="Les entreprises qui nous font confiance"
        testimonials={testimonialData.testimonials}
      />
      <TryDechetterie />
    </MainPageWrapper>
  );
};
const HeroSection = () => {
  const { phoneLink, phoneDisplay, CONTACT_EMAIL } = useEnv() || {};
  const { conseilPageData } = useLoaderData<typeof loader>();
  const { isLargeScreen } = useTailwindScreenSizes({});

  return (
    <HomeSection
      color={isLargeScreen ? "gray100" : "white"}
      verticalPadding={"clint"}
      horizontalPadding={"clint"}
    >
      <HomeArticle color="transparent" horizontalPadding="none">
        <div className="relative flex flex-wrap items-center gap-8 lg:gap-12">
          <div className="relative inset-0 z-20 flex items-center lg:absolute">
            <div
              className={
                "rounded-clint flex flex-col gap-5 bg-white lg:basis-[672px] lg:border lg:border-gray-200 lg:p-[2.125rem]"
              }
            >
              <div className="flex flex-col gap-2">
                <Label size="M" className="text-gray-600">
                  Conseil
                </Label>
                <Headline size="h1">
                  {conseilPageData.heroSection.title}
                </Headline>
              </div>
              <Label size="M" className="py-5 text-xl">
                {conseilPageData.heroSection.description}
              </Label>

              <div className="flex w-full flex-wrap items-center gap-8">
                <ClintButton
                  as="Link"
                  iconPosition="left"
                  linkProps={{
                    to: phoneLink || "#",
                    reloadDocument: true,
                  }}
                  variant="secondaryGray100"
                  size="lg"
                  Icon={Phone}
                  className="grow basis-[240px]"
                >
                  {phoneDisplay}
                </ClintButton>
                <ClintButton
                  as="Link"
                  iconPosition="left"
                  linkProps={{
                    to: `mailto:${CONTACT_EMAIL}` || "#",
                    reloadDocument: true,
                  }}
                  variant="secondaryGray100"
                  size="lg"
                  Icon={Mail}
                  className="grow basis-[240px]"
                >
                  {CONTACT_EMAIL}
                </ClintButton>
              </div>
            </div>
          </div>

          <div className="relative overflow-hidden lg:min-w-[500px] lg:ml-auto">
            <OptimizedImage
              width={905}
              height={464}
              maxWidth={905}
              source={conseil}
              className="rounded-clint h-auto w-full"
              containerClassName="mx-0 relative"
              alt={`gestion des dechets quelles sont les reglementations a connaitre`}
              imageProps={{
                minHeight: "auto",
                objectFit: "contain",
              }}
            >
              <div className="absolute inset-0 z-10 flex h-full w-full items-center justify-center">
                <img src={vector} className="" width={530} height={530}  alt="cercle GoodCollect" />
              </div>
            </OptimizedImage>
          </div>
        </div>
      </HomeArticle>
    </HomeSection>
  );
};

const TrustedByBrands = () => {
  const { conseilPageData } = useLoaderData<typeof loader>();
  return (
    <HomeSection horizontalPadding={"none"} verticalPadding={"clint"}>
      <HomeArticle horizontalPadding={"none"}>
        <div className="relative min-h-[110px] w-full overflow-hidden">
          <Label
            size={"L"}
            as="p"
            className="text-secondary mb-3.5 text-center"
          >
            Ils nous font confiance
          </Label>
          <LogoBanner brands={conseilPageData.brands} colorGradient="grey" />
        </div>
      </HomeArticle>
    </HomeSection>
  );
};

export default ConseilPage;

const AuditSection = () => {
  const audits = [
    {
      number: 1,
      title: "Identification des chiffres clés",
      description:
        "Tonnage total, Taux de recyclage, taux d'enfouissement, coût total de la gestion de vos déchets, temps opérationnel. Faites le choix de l'audit pour y voir plus clair sur vos indicateurs. ",
    },
    {
      number: 2,
      title: "Analyse du marché",
      description:
        "Cours de marché du rachat trop bas, prix de prestations élevés, augmentations annuelles, outils de collecte ou de traitement adaptés à votre besoin ... Grâce à l'audit vous pourrez identifier votre positionnement déchet",
    },
    {
      number: 3,
      title: "Identification des leviers de performances",
      description:
        "Objectif finale de l'audit, identifier les leviers qui vous vous permettre d'améliorer la gestion de vos déchets.",
    },
  ];
  return (
    <HomeSection
      color="white"
      verticalPadding={"clint"}
      horizontalPadding={"clint"}
    >
      <HomeArticle color={"transparent"}>
        <Tag type="Square" variant="Dark" className="flex items-center gap-2">
          <MagicWand /> <span>À nos frais</span>
        </Tag>
        <div className="flex flex-wrap items-center justify-between gap-4 lg:gap-8">
          <Headline size={"h2"} className="max-w-[65ch]">
            Réalisation d'un audit de maturité
          </Headline>
          <Label size="M" className="max-w-[65ch]">
            Vous souhaitez connaitre votre positionnement par rapport au marché
            et ainsi élaborer le plan d'action le plus optimal pour améliorer
            votre performance déchet ? Contactez nous.
          </Label>
        </div>
        <div className="grid-cols-smallBlogArticle grid gap-6">
          {audits.map((audit) => (
            <div key={audit.number} className="flex flex-col gap-4 lg:gap-8">
              <Label size="M" as="p" className="text-green-400">
                {audit.number.toString().padStart(2, "0")}
              </Label>
              <div className="flex flex-col gap-2">
                <Headline size="h6">{audit.title}</Headline>
                <Label size="S" as="p">
                  {audit.description}
                </Label>
              </div>
            </div>
          ))}
        </div>
      </HomeArticle>
    </HomeSection>
  );
};

const ImprovePerformanceSection = () => {
  const performanceItems = [
    {
      id: "performance-1",
      name: "Appel d'offres",
      description:
        "Chez GoodCollect, nous comprenons l'importance stratégique des appels d'offres pour votre entreprise. C'est pourquoi nous vous accompagnons à chaque étape du processus, de la préparation des documents à la soumission finale. Notre expertise vous garantit des propositions solides et compétitives, augmentant ainsi vos chances de succès.",
      imageUrl: appelDoffres,
    },
    {
      id: "performance-2",
      name: "Nouvelles filières",
      description:
        "Un de vos déchets, vous pose problème car votre prestataire ne peut vous proposer une solution de recyclage. Chez GoodCollect, grâce à notre réseau de prestataire et nos équipés dédiés à la recherche de filière, nous collaborerons avons probablement des solutions.",
      imageUrl: nouvellesFilieres,
    },
    {
      id: "performance-3",
      name: "Excelence opérationnelle",
      description:
        "Le geste de tri au sein d’une entreprise est souvent un coût oublié dans la gestion des déchets. Chez GoodCollect, nous savons l’identifier et le rendre performant pour fournir à vos opérateurs du temps pour leur spécialités.",
      imageUrl: excellenceOperationnelle,
    },
    {
      id: "performance-4",
      name: "Gestion déléguée",
      description:
        "Vous souhaitez déléguer l’ensemble de la gestion de vos déchets à un tiers ? GoodCollect accompagne ces clients sur l’ensemble de cette gestion et propose des mécanismes innovants pour  positionner ces clients au plus haut niveau de maturité de la gestion des déchets.",
      imageUrl: gestionDeleguee,
    },
    {
      id: "performance-5",
      name: "Digitalisation déchets custom",
      description:
        "Vous souhaitez digitaliser vos prestation de gestion des déchets mais vous ne connaissez pas l’outil GoodCollect ? Contactez-nous pour une démo afin de vous projeter. De plus GoodCollect à la capacité de proposer son outil en version custom pour ces clients afin de le rendre parfaitement adapté.",
      imageUrl: digitalisationDechetsCustom,
    },
    {
      id: "performance-6",
      name: "Intégration CO2 déchet",
      description:
        "La problématique du CO2 vous intéresse ? Votre entreprise souhaite l’intégrer dans ces démarches RSE mais vous ne savez pas comment aborder le sujet avec vos prestataires du déchet ? Nous pouvons dans ce cas vous accompagner sur la problématique et prévoir même de le réduire au maximum.",
      imageUrl: integrationCo2Dechet,
    },
    {
      id: "performance-7",
      name: "Etudes spécifiques",
      description:
        "Vous souhaitez en  apprendre plus sur toutes autres problématiques spécifiques liées aux déchets ? Contactez nous pour obtenir plus d’informations à ce sujet ",
      imageUrl: etudesSpecifiques,
    },
  ];
  const PerformanceItem = ({
    performance,
  }: {
    performance: (typeof performanceItems)[0];
  }) => {
    return (
      <div className="rounded-clint flex flex-col gap-3 overflow-hidden border border-gray-200 lg:gap-8">
        <OptimizedImage
          maxWidth={400 * 1.5}
          width={400 * 1.5}
          height={300 * 1.5}
          source={performance.imageUrl}
          containerClassName="mx-0"
          alt={performance.name}
        >
          <div className="absolute inset-0 z-10 flex items-center justify-center">
            <img src={vector} className="scale-50" width={530} height={530}  alt="cercle GoodCollect" />
          </div>
        </OptimizedImage>
        <div className="flex flex-col gap-2 p-3.5 lg:p-5">
          <Headline size="h4">{performance.name}</Headline>
          <Paragraph size="sm" variant={"secondary"}>
            {performance.description}
          </Paragraph>
        </div>
      </div>
    );
  };
  return (
    <HomeSection
      color="white"
      verticalPadding={"none"}
      horizontalPadding={"clint"}
    >
      <HomeArticle
        color={"transparent"}
        className="border-t border-gray-200"
        verticalPadding={"clint"}
        horizontalPadding={"none"}
      >
        <div className="flex flex-wrap items-center justify-between gap-4 lg:gap-8">
          <Headline size={"h2"} className="max-w-[25ch]">
            Mise en place des leviers d'amélioration de la performance
          </Headline>
          <Label size="M" className="max-w-[65ch]">
            Spécialisé dans la récupération et l'élimination des biodéchets,
            matières et matériaux divers, nous vous aidons à garder un
            environnement propre et à participer au développement d'une société
            durable. Nos solutions de valorisation des déchets s'adressent aux
            entreprises et particuliers.
          </Label>
        </div>
        <div className="grid-cols-conseil mt-14 grid gap-4">
          {performanceItems.map((performance) => (
            <PerformanceItem key={performance.id} performance={performance} />
          ))}
          <CardContact title="Contactez-nous pour un devis" />
        </div>
      </HomeArticle>
    </HomeSection>
  );
};

export const FreeAuditSection = ({
  description,
  title,
}: {
  title: string;
  description: string;
}) => {
  const { phoneLink, phoneDisplay, CONTACT_EMAIL } = useEnv() || {};
  return (
    <HomeSection
      color="tertiary"
      verticalPadding={"clint"}
      horizontalPadding={"clint"}
      className="relative overflow-hidden"
    >
      <div className="pointer-events-none absolute inset-0 z-10 flex items-center justify-center">
        <OptimizedImage
          source={whiteVector}
          width={5760}
          height={1792}
          maxWidth={1200}
          alt="cercle GoodCollect"
          className="h-full w-full object-cover"
        />
      </div>
      <HomeArticle
        color={"transparent"}
        verticalPadding={"none"}
        horizontalPadding={"none"}
        className="z-20 flex flex-row flex-wrap items-center justify-between gap-8"
      >
        <div className="flex max-w-[65ch] flex-wrap items-center justify-between gap-5 lg:py-12">
          <Headline size={"h2"} className="max-w-[25ch]">
            {title}
          </Headline>
          <Label size="M">{description}</Label>
        </div>
        <div className="flex flex-col gap-4">
          <ClintButton
            as="Link"
            iconPosition="left"
            linkProps={{
              to: phoneLink || "#",
              reloadDocument: true,
            }}
            variant="secondaryGray100"
            size="lg"
            Icon={Phone}
            className="w-fit grow"
          >
            {phoneDisplay}
          </ClintButton>
          <ClintButton
            as="Link"
            iconPosition="left"
            linkProps={{
              to: `mailto:${CONTACT_EMAIL}` || "#",
              reloadDocument: true,
            }}
            variant="secondaryGray100"
            size="lg"
            Icon={Mail}
            className="w-fit grow"
          >
            {CONTACT_EMAIL}
          </ClintButton>
        </div>
      </HomeArticle>
    </HomeSection>
  );
};

const ActivitySectorsSection = () => {
  const activitySectors = [
    {
      id: "activity-1",
      name: "Retail",
      imageUrl: retail,
    },
    {
      id: "activity-2",
      name: "Restauration",
      imageUrl: restauration,
    },
    {
      id: "activity-3",
      name: "Industriel",
      imageUrl: industriel,
    },
    {
      id: "activity-4",
      name: "Commerce",
      imageUrl: commerce,
    },
    {
      id: "activity-5",
      name: "Logistique",
      imageUrl: logistique,
    },
  ];
  const ActivityItem = ({
    activity,
  }: {
    activity: (typeof activitySectors)[0];
  }) => {
    return (
      <OptimizedImage
        maxWidth={242}
        width={242}
        height={242}
        imageProps={{
          minHeight: "auto",
          objectFit: "cover",
        }}
        source={activity.imageUrl}
        containerClassName="mx-0 shrink-0"
        className="rounded-clint"
        alt={activity.name}
      >
        <div className="absolute inset-0 z-10 flex items-center justify-center overflow-hidden bg-sky-900/20">
          <img
            src={vector}
            className="z-30 scale-50"
            alt="cercle GoodCollect"
          />
        </div>
        <Headline
          size="h4"
          as="div"
          variant="white"
          className="absolute bottom-4 left-8 z-20"
        >
          {activity.name}
        </Headline>
      </OptimizedImage>
    );
  };

  return (
    <HomeSection color="white" horizontalPadding={"clint"}>
      <HomeArticle
        horizontalPadding={"none"}
        color={"transparent"}
        className="flex flex-col gap-12"
      >
        <Headline size={"h2"} className="text-center">
          Nos différents secteurs d'activités
        </Headline>

        <div className="relative w-full overflow-hidden">
          <div className="gradientLogos absolute z-10 h-full w-full"></div>

          <div className="animate-slide-left flex h-full w-full items-center gap-2">
            {activitySectors.map((activity) => (
              <ActivityItem key={activity.id} activity={activity} />
            ))}

            {activitySectors.map((activity) => (
              <ActivityItem
                key={`${activity.id}-duplicate`}
                activity={activity}
              />
            ))}
          </div>
        </div>
      </HomeArticle>
    </HomeSection>
  );
};

const BenefitsSection = () => {
  const Section = ({ advantage }: { advantage: (typeof advantages)[0] }) => {
    return (
      <div className="flex w-full max-w-[242px] flex-col gap-2 border-l border-r-gray-500 pl-3">
        {advantage.icon}
        <Label
          size="M"
          as="p"
          variant={"secondaryDarker"}
          className="w-full grow"
        >
          {advantage.name}
        </Label>
      </div>
    );
  };
  return (
    <HomeSection color="white" verticalPadding={"none"}>
      <HomeArticle
        color={"transparent"}
        className="border-t border-gray-200"
        verticalPadding={"clint"}
      >
        <div className="flex w-full flex-wrap justify-between gap-4 lg:gap-8">
          <Headline size={"h2"} className="max-w-[18ch]">
            Les bénéfices d'une collaboration avec nos experts GoodCollect
          </Headline>

          <div className="text-primary grid w-fit basis-[484px] grid-cols-2 items-end justify-end gap-x-4 gap-y-8">
            {advantages.map((adv) => (
              <Section advantage={adv} key={adv.id} />
            ))}
          </div>
        </div>
      </HomeArticle>
    </HomeSection>
  );
};

const ChiffresClesSection = () => {
  const ChiffreCleItem = ({
    title,
    subtitle,
    numberTitle,
  }: {
    title: string;
    subtitle: string;
    numberTitle: string;
  }) => {
    return (
      <div className="flex grow basis-[324px] flex-col gap-2 self-stretch border-l border-r-gray-500 pl-3">
        <Headline size="h3" as="div" variant={"primary"} className="w-full">
          {numberTitle}
        </Headline>
        <Headline size="h3" as="div" variant={"primary"} className="w-full">
          {title}
        </Headline>
        <Label size="M">{subtitle}</Label>
      </div>
    );
  };
  return (
    <HomeSection color="gray100" verticalPadding={"clint"}>
      <HomeArticle color={"transparent"}>
        <div className="flex w-full flex-col items-center gap-6 lg:gap-[3.75rem]">
          <div className="flex flex-col items-center gap-2 lg:gap-4">
            <Label size="M">Chiffres clés</Label>
            <Headline size={"h2"} className="max-w-[18ch]">
              Nos collaborations
            </Headline>
          </div>
          <div className="flex w-full flex-wrap items-stretch gap-x-4 gap-y-8">
            <ChiffreCleItem
              numberTitle="+17 %"
              title="d'économie"
              subtitle="C'est la moyenne des économies apportées par GoodCollect pour ses clients."
            />
            <ChiffreCleItem
              numberTitle="+8 %"
              title="de recyclage"
              subtitle="C'est le taux valorisé par GoodCollect pour ses clients ayant des problématiques environnementales."
            />
            <ChiffreCleItem
              numberTitle="+5 à 6h"
              title="gagnées par mois"
              subtitle="C'est le temps gagné chaque mois par les services RSE grâce à la digitalisation."
            />
          </div>
        </div>
      </HomeArticle>
    </HomeSection>
  );
};
