import type { RenderableTreeNodes } from "@markdoc/markdoc";
import { OptimizedImage } from "components/OptimizedImage";
import { Markdown } from "components/Sections/Markdown";
import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  Accordion as AccordionWrapper,
} from "components/shadcn-ui/accordion.tsx";
import { cn } from "utils/utils";
import { Label } from "./Label";

export type FaqItemType =
  | {
      description: RenderableTreeNodes;
      id: number;
      title: string;
      numbered?: false;
      img?: string;
    }
  | {
      description: RenderableTreeNodes;
      id: number;
      title: string;
      numbered: true;
      number: number;
      img?: string;
    };
export const Accordion = ({
  items,
  theme,
  onValueChange,
  className,
}: {
  items: FaqItemType[];
  theme?: "drawer" | "faq";
  onValueChange?: (value: string) => void;
  className?: string;
}) => {
  return (
    <div className={cn("w-full", className)}>
      <AccordionWrapper
        // @ts-expect-error
        collapsible={"true"}
        type={theme === "faq" ? "multiple" : "single"}
        onValueChange={(value) => onValueChange?.(value)}
      >
        {items.map((faqItem) => (
          <AccordionItem key={faqItem.id} value={faqItem.id.toString()}>
            <AccordionTrigger
              theme={theme}
              className="w-full border-b border-b-gray-300 py-6 text-start cursor-pointer"
            >
              <Label
                size={"M"}
                variant={"secondary"}
                className="flex items-center gap-2.5 hover:cursor-pointer"
              >
                {faqItem.numbered ? (
                  <span className="text-green-600">
                    {faqItem.number.toString().padStart(2, "0")}
                  </span>
                ) : null}
                <span>{faqItem.title}</span>
              </Label>
            </AccordionTrigger>
            <AccordionContent>
              <Markdown
                className="prose-p:text-base prose-p:text-midnightblue mt-2 lg:pr-12"
                content={faqItem.description}
              />
              {faqItem.img && (
                <OptimizedImage
                  className="lg:hidden"
                  containerClassName="flex py-4 justify-end mx-0 w-full"
                  source={faqItem.img}
                  alt={faqItem.title}
                  imageProps={{ minHeight: "100%", objectFit: "contain" }}
                  width={642}
                  height={546}
                  maxWidth={642}
                />
              )}
            </AccordionContent>
          </AccordionItem>
        ))}
      </AccordionWrapper>
    </div>
  );
};
