import { useEnv } from "app/root";
import { OptimizedImage } from "components/OptimizedImage";
import { ClintButton } from "components/shadcn-ui/button";
import { Mail, Phone } from "iconoir-react";
import { cn } from "utils/utils";
import { Headline } from "./Headline";
import greenVector from "/images/green-vector.png";

export const CardContact = ({
  title,
  className,
}: {
  title: string;
  className?: string;
}) => {
  const { phoneDisplay, phoneLink, CONTACT_EMAIL } = useEnv() || {};
  return (
    <div
      className={cn(
        "overflow-hidden relative bg-secondary flex flex-col gap-10 justify-between rounded-clint p-5",
        className,
      )}
    >
      <Headline size={"h4"} as="div" className="z-10 text-white">
        {title}
      </Headline>

      <div className="z-10 mt-auto flex gap-2 items-start flex-col w-fit">
        <ClintButton
          as="Link"
          iconPosition="left"
          linkProps={{
            to: phoneLink || "#",
            reloadDocument: true,
          }}
          variant="secondaryGray100"
          size="lg"
          Icon={Phone}
          className="w-fit"
        >
          {phoneDisplay}
        </ClintButton>
        <ClintButton
          as="Link"
          iconPosition="left"
          linkProps={{
            to: `mailto:${CONTACT_EMAIL}` || "#",
            reloadDocument: true,
          }}
          variant="secondaryGray100"
          size="lg"
          Icon={Mail}
          className="w-fit"
        >
          {CONTACT_EMAIL}
        </ClintButton>
      </div>
      <OptimizedImage
        alt="Contactez GoodCollect"
        source={greenVector}
        width={624}
        height={624}
        maxWidth={624}
        containerClassName="mx-0 absolute -right-12 opacity-75 -top-4 lg:scale-100"
      />
    </div>
  );
};
